.hero__container {
    display: grid;
    grid-template-columns: 1fr;
    height: 100vh;
    justify-content: center;
    max-width: 1024px;
    /* background-color: #000; */
}


.left_top__section {
    display: grid;
    align-items: center;
    justify-content: center;

    /* background-color: green; */
}

.left_top__content {
    height: 30%;
}

.right_btm__section {
    display: grid;
    align-items: center;
    height: 50vh;
    /* background-color: yellow; */
}

.right_btm__content {
    display: grid;
    align-items: center;
    height: 100%;
    justify-content: center;
}



@media only screen and (min-width: 1024px) {
    .hero__container {
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        height: 100vh;
        margin: auto auto;
    }

    .left_top__section {
        height: 100%;
        padding: unset;
        margin: unset;
    }

    .right_btm__section {
        height: 100%;
    }

    .left_top__content {
        margin-left: 4.5rem;
    }

    right_btm__content {
        height: 30%;
        max-width: 100%;
    }
}