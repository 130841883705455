/* MOBILE FULL PAGE INTRO*/
header {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0 100px;
    justify-content: flex-end;
    align-items: center;
    transition: 1s;
    z-index: 10;
}

header .banner {
    position: absolute;
    top: 0;
    left: 0;
    background-size: 200%;
    background-position: 30% 100%;
    width: 100vw;
    height: 100%;
    /* object-fit: cover; */
    background-repeat: no-repeat;
    background-image: url('../../assets/bg_black.webp'),
        linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 100%);
    opacity: 1;
}

header .banner__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    background-color: black;
    opacity: 0.8;
    border-bottom: rgba(255, 39, 95, 0);
    border-width: 0px;
    border-style: solid;
}

header .logo {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    /* font-family: var(--font-button); */
    font-family: "Aquire";
    font-size: 10vw;
    color: #fff;
    font-weight: 700;
    transition: 1s;
    text-decoration: none;
}

header .roberts {
    position: absolute;
    background: linear-gradient(45deg, rgba(0, 194, 255, 1), rgba(250, 0, 255, 1));
    /* color: #ff275f; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

header .roberts__bg {
    background: linear-gradient(45deg, rgba(0, 194, 255, 1), rgba(250, 0, 255, 1));
    filter: blur(25px);
    opacity: 0.5;
    z-index: 4;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

nav {
    position: relative;
    display: flex;
    margin-right: -3rem;
    z-index: 1;
}

nav ul {
    font-family: "Aquire";
    position: relative;
    display: flex;
    transition: 0.25s;

    opacity: 0;
    visibility: hidden;
    align-items: center;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    color: #fff;
    display: inline-block;
    padding: 5px 15px;
    font-size: 0.8em;
    text-decoration: none;
}

nav ul li div {
    color: #fff;
    display: inline-block;
    padding: 5px 15px;
    font-size: 0.8em;
    text-decoration: none;
}

.nav__navlink:hover {
    color: #ff275f;
    cursor: pointer;
}

.nav__social {
    display: none;
    opacity: 0;
}

header .toggle {
    opacity: 0;
    display: none;
}

#mouse_icon_id {
    position: absolute;
    align-self: center;
    justify-self: center;
    display: grid;
    z-index: 11;
    top: 70vh;
    width: 100vw;
}

.mouse_icon__button {
    display: flex;
    background-size: 50%;
    background-position: center;
    align-self: center;
    justify-self: center;
    aspect-ratio: 1/1;
    background-image: url("../../assets/mouse_small.png");
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    -webkit-animation: heartbeat 1.5s steps(3, start) 3s infinite both;
    animation: heartbeat 1.5s steps(3, start) 3s infinite both;
}


/* MOBILE FULL STICKY*/

header.sticky {
    height: 60px;
}

header.sticky .banner {
    transition: 2s;
    opacity: 0;
}

header.sticky .banner__overlay {
    transition: 1s;
    width: 100vw;
    background-color: rgb(6, 1, 22, 0.95);
    opacity: 0.9;
    border-image: linear-gradient(45deg, rgba(0, 194, 255, 0.5), rgba(250, 0, 255, 0.6)) 1;
    border-width: 0 0 2px 0;
    border-style: double;
    filter: drop-shadow(0px 0px 10px #4444dd);
}

header.sticky nav ul {
    transition: 2s;
    transition-delay: 0.2s;
    opacity: 1;
    visibility: visible;
}

header.sticky .logo {
    top: 50%;
    position: absolute;
    font-size: 1.5em;
    left: 50px;
    transform: translate(-5%, -50%);
}

header.sticky .nav__social {
    opacity: 1;
    transition: 2s;
    transition-delay: 0.2s;
}

header.sticky .toggle {
    transition: 1s;
    opacity: 1;
    display: unset;
}

/* NAV DESKTOP : FULL PAGE INTRO */
@media only screen and (max-width: 1025px) {
    header {
        padding: 40px;
    }

    nav ul {
        display: none;
        opacity: 0;
        visibility: hidden;
    }

    #mouse_icon_id {
        top: 80vh;
    }

    .mouse_icon__button {
        min-width: 140px;
        max-width: 145px;
    }



    /* NAV DESKTOP : STICKY */

    header.sticky .logo {
        left: 40px;
        font-size: 1.2em;
    }

    header.sticky nav ul {
        font-size: 2em;
    }

    header.sticky .toggle {
        position: fixed;
        top: 15px;
        right: 40px;
        width: 50px;
        height: 50px;
        cursor: pointer;
        /* background: #fff url('../../assets/menu.png'); */
        background-size: 30px;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 10;
    }

    header.sticky .toggle.active {
        /* background: #fff url('../../assets/close.png'); */
        background-size: 25px;
        background-repeat: no-repeat;
        background-position: center;
    }

    nav.active ul {
        position: fixed;
        top: 0px;
        left: 0;
        width: 100vw;
        height: calc(100%);
        background: rgba(0, 0, 0, 0.95);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}


@media only screen and (min-width: 1025px) {
    header .toggle {
        display: none;
    }

    header.sticky .toggle {
        display: none;
    }

    header .banner {
        background-size: 140%;
        background-position: 30% 40%;
    }

    #mouse_icon_id {
        top: 80vh;
    }

    .mouse_icon__button {
        min-width: 130px;
        max-width: 135px;
    }
}

/* NAV LARGE : FULL PAGE INTRO */
@media only screen and (min-width: 1280px) {
    .nav__social {
        display: unset;
    }
}


/* ANIMATIONS */
@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}