:root {
  /* --font-button: 'Aquire', sans-serif; */
}



@font-face {
  font-family: 'Aquire';
  src: local("Aquire"), url("./fonts/Aquire.otf") format("truetype");
  font-display: initial;
}


*, html {
  max-width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {

  /* min-height: 2000px; */
  margin: 0;
  font-family: Space Grotesk, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  background: #000;
  color: hsl(204, 23.8%, 95.9%);
  overflow-x: hidden;
}


body::-webkit-scrollbar- {
  display: none;
}


body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* .bg_anim {
  background-image: url('./assets/portfolio_bg.svg');
  width: 100vw;
  height: 100%;
  background-size: 100px 100px;

  background-repeat: repeat;
  z-index: -1;
} */


.bg_gradient {

  /* ff 3.6+ */
  background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(9, 39, 74, 1) 64%, rgba(0, 0, 0, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(9, 39, 74, 1) 64%, rgba(0, 0, 0, 1) 100%);

  /* opera 11.10+ */
  background: -o-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(9, 39, 74, 1) 64%, rgba(0, 0, 0, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);

  /* ie 10+ */
  background: -ms-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(9, 39, 74, 1) 64%, rgba(0, 0, 0, 1) 100%);

  /* global 94%+ browsers support */
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(9, 39, 74, 1) 64%, rgba(0, 0, 0, 1) 100%);


  width: 100vw;
  height: 100%;

  z-index: -1;
}