.gameCanvas {
    width: 100%;
    height: 500px;
    max-height: 500px;
    align-self: center;
    justify-self: center;
    /* border-radius: 10px;
    border-width: 2px;
    border-color: darkblue; */
    border-style: ridge;
    box-shadow: 10% 10% 10% 10%;
}

.unity__container {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    height: auto;
}

@media(max-width: 640px) {
    .gameCanvas {
        height: 300px;
    }
}